import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gis from "/src/components/img/gis"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Studijní materiály GIS, geoinformatika a kartografie" />
    <h1>Studijní materiály GIS, geoinformatika a&nbsp;kartografie</h1>
    <p>Níže jsou sepsány&nbsp;on-line učební materiály <strong>z různých předmětů o kartografii a GIS</strong> na brněnské geografii&nbsp;na MUNI z mého studia mezi lety 2017-2021. Jde o přepsané zápisky z přednášek doplněné o poznatky z rozlišných zdrojů, zejména pak ze skript V. Voženílka <a href="https://is.muni.cz/el/1441/podzim2014/Ze0013/um/Vozenilek_kartogafie.pdf">Zásady tvorby mapových výstupů</a> či článků časopisu <a href="https://www.geobusiness.cz/">Geobusiness</a>.</p>
    <p><em>(Kdyby se Vám náhodou chtělo, můžete mě podpořit lajkem na <a href="https://www.facebook.com/zemepisec">Fejsbůku</a>)</em></p>
    <hr />
    <ImgCon><Gis /><div><h2>Co je GIS a geoinformatika? A co kartografie?</h2>
    <p>Geografický informační systém (zkráceně <strong>GIS</strong>) se stará o získávání, uchovávání, vyhodnocování a spojování prostorových informací. V praxi jej lze využít například pro výběr toho nejlepšího místa pro nové nákupní centrum. <strong>Geoinformatika</strong> pak zahrnuje hlavně práci s GIS a digitálními mapami. Zjednodušeně jde o informatiku zaměřenou na geovědní a prostorové aplikace. Pojem <strong>kartografie</strong> existuje narozdíl od předešlých pojmů již nějaké to století a zastřešuje disciplínu zabývající se tvorbou map ze všech možných úhlů.</p>
    <p><em>(omlouvám se, na této sekci se právě pracuje :))</em></p></div></ImgCon>
    <hr />
    <h2>GIS, geoinformatická a kartografická témata studijních materiálů</h2>
    <ul>
    <li>
      <Link to="/gis/mapova-kompozice/">Mapová kompozice</Link></li>
      <ul>
      <li>
        <Link to="/gis/mapova-kompozice/meritko/">Měřítko na mapě</Link>
      </li>
      <li>
        <Link to="/gis/mapova-kompozice/legenda/">Legenda mapy</Link>
      </li>
      <li>
        <Link to="/gis/mapova-kompozice/nazev-mapy/">Název mapy</Link>
      </li>
      <li>
        <Link to="/gis/mapova-kompozice/smerovka/">Směrovka na mapě</Link>
      </li>
      </ul>
    </ul>
    <p><em>Na rozšíření materiálů se právě teď pracuje :)</em></p>
  </Layout>
)
